import { React, useEffect } from 'react';
import { useState } from 'react';
import './App.css';
import Chat from "../ChatPanel/Chat";
import WelcomePage from "../WelcomePage/WelcomePage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faYoutube,
    faTwitter
} from '@fortawesome/free-brands-svg-icons';
import banner_image from '../../banner_image.png';


function App () {

    //State Variables
    const [welcomePanel, setWelcomePanel] = useState(true);
    const [maintenanceMode, setMaintenanceMode] = useState(false);
    const [user, setUser] = useState('');
    const [mcecsUserName, setMcecsUserName] = useState('');
    const [informationAboutIssue, setInformationAboutIssue] = useState('');

    // On-load hook - runs one when App component loads
    useEffect(() => {
        fetch('/health')
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if(data.status !== "up"){
                    setMaintenanceMode(true);
                    console.log("Setting portal to maintenance mode.");
                }
            });
    }, []);

    //Gets information about the user - passed down to the WelcomePage component
    const getUserInformation = (user, mcecsUserName, info) => {
        setUser(user);
        setMcecsUserName(mcecsUserName);
        setInformationAboutIssue(info);
        setWelcomePanel(false);
    }

    function cleanAndConvertMessage(messageText) {
        let cleanedMessage = messageText

        // Convert markdown into HTML
        //cleanedMessage = converter.makeHtml(cleanedMessage);

        //Check for JS link injection
        const jsInjectionRegex = "<a(.)*href=(\"|')javascript:(.)*</a>";
        const matches = cleanedMessage.match(jsInjectionRegex);
        if(matches !== null && matches.length > 0){
            cleanedMessage = "MESSAGE REDACTED DUE TO MALICIOUS INPUT";
        }
        return cleanedMessage;
    }

    return (
        <div>
            {/*--------------------------------Header--------------------------------------*/}
            <div className="info-pane">
                <span className="header-icons">
                  <a href="https://twitter.com/cat_alerts" target="_blank" style={{paddingRight: '15px'}}>
                    <span className="sr-only">cat_alerts Twitter</span>
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>

                  <a href="https://www.youtube.com/user/catpsu/" target="_blank">
                    <span className="sr-only">cat YouTube channel</span>
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                </span>
                    <span className="contact-info">
                  <a href="tel:1-503-725-5420">503-725-5420</a> |
                  <a href="mailto:support@cat.pdx.edu"> support@cat.pdx.edu</a>
                </span>
            </div>

            <div className="mcecs-cat-logo d-flex justify-content-between px-4">
                <a href="https://cat.pdx.edu/">
                    <img className="header-logo" src={banner_image} alt="Portland State Computer Action Team"></img>
                </a>
                <h3 className="d-none d-md-block"> Support Chat </h3>
            </div>

            {/*--------------------------------Body--------------------------------------*/}
            <div className="container mt-0 mt-md-5" id={"welcomeMessage"}>
                <div className="chat-window">
                    <div className="p-0 p-md-3 mb-5 bg-white rounded">
                        <div className="card h-50 mb-3" style={{border: 'none'}}>
                            {!maintenanceMode ? (
                                <ul id="messages" className="list-group list-group-flush mt-auto">
                                    {welcomePanel ? (<WelcomePage setUserInformation={getUserInformation} cleanAndConvertMessage={cleanAndConvertMessage} />) :
                                        (<Chat user={user}
                                               mcecsUserName={mcecsUserName}
                                               issueInfo={informationAboutIssue}
                                               cleanAndConvertMessage={cleanAndConvertMessage}
                                        />)}
                                </ul> )
                                :
                                (<div>
                                    <div className="alert alert-warning" role="alert">
                                        <h4 className="alert-heading">Server Maintenance</h4>
                                        <p>Due to scheduled server maintenance, our support chat portal is currently down. <br/>
                                            We are still open during our normal hours and can be reached by phone at 503-725-5420, by email at
                                            support@cat.pdx.edu or in-person in FAB 82.
                                        </p>
                                        <hr/>
                                            <p className="mb-0">
                                                Please visit our <a href="https://cat.pdx.edu/contact-us/" className="alert-link">contact page</a> for more information.
                                            </p>
                                    </div>
                                </div>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
