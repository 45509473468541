import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import './WelcomePage.css'

function WelcomePage(props) {
    //State variables

    const [user, setUser] = useState("");
    const [mcecsUserName, setMcecsUserName] = useState("");
    const [issueInfo, setIssueInfo] = useState("");
    const [wordCount, setWordCount] = useState(0);
    const [whitespaceLength, setWhitepaceLength] = useState(0);

    const handleSubmit = (event) => {
        event.preventDefault();

       let userWithoutSpaces = user.replaceAll(" ", "");

        if (!userWithoutSpaces) {
            window.alert("Please enter your name");
        } else {
            console.log("Mcecs Username: ", mcecsUserName);
            console.log("User: ", user);
            props.setUserInformation(user, mcecsUserName, issueInfo);
        }
    }

    const handleChangeUser = (event) => {
        event.preventDefault();
        let cleanedUser = (event.target.value).substring(0, 35).replaceAll(/[-!$%^&*()+|~=`{}\[\]:";'<>?,.\/]/g, '').replace(/</g, "&lt;").replace(/>/g, "&gt;");
        setUser(cleanedUser);
    }

    const handleChangeMcecs = (event) => {
        event.preventDefault();
        let cleanedMcecsUser = (event.target.value).substring(0, 35).replaceAll(/[-!$%^&*()+|~=`{}\[\]:";'<>?,.\/]/g, '').replace(/</g, "&lt;").replace(/>/g, "&gt;");
        setMcecsUserName(cleanedMcecsUser);
    }

    const handleChangeIssueInfo = (event) => {
        event.preventDefault();
        setWhitepaceLength(event.target.value.length - event.target.value.replace(/ /g, '').length);
        setWordCount(event.target.value.replace(/ /g, '').length);
        let cleanedIssueInfo = props.cleanAndConvertMessage(event.target.value);
        setIssueInfo(cleanedIssueInfo);
    }

    return (
        <div>
            <li className="list-group-item">
                <div className="d-block">
                    <h5 className={"text-center"}>Welcome to our support channel!</h5>
                    <hr/>
                    <div className={"d-block mx-0 mx-md-5"}>
                        <ul className={"fa-ul"}>
                            <li>
				<span className="fa-li">
				    <i className="fas fa-clock"/>
				</span>

                                Our hours are <strong>8am-6pm</strong> Monday to Friday, and <strong>12-5pm</strong> on Saturdays.
                            </li>

                            <li>
				<span className="fa-li">
				    <i className="fas fa-info-circle"/>
				</span>

				Please allow a couple minutes for a response during these hours.  Outside our normal hours you may be able to contact us here, but a response is not guaranteed.
                            </li>

                            <li>
				<span className="fa-li">
				    <i className="fas fa-users"/>
				</span>
				This is a public channel. Please do not share passwords or other sensitive information here.
                            </li>

                            <li>
				<span className="fa-li">
				    <i className="fas fa-user"/>
				</span>

				Need an MCECS account or forgot your password? You can activate your account or reset your password via&nbsp;
				<a href="https://cat.pdx.edu/cram" target="_blank">
				    CRAM
				</a>
			    </li>

                            <li>
				<span className="fa-li">
				    <i className="fas fa-building"/>
				</span>

				If you have a question about PSU IT services that is not related to MCECS, please visit OIT&nbsp;
				<a href="https://www.pdx.edu/technology/support" target="_blank">
				    here.
				</a>
			    </li>
                        </ul>
                    </div>

                </div>
            </li>
            <br/>
            {/*---------------------Form takes in information about user-------------------*/}
            <form id="welcomeForm">
                <h5 className="text-center">Please enter your name to start a support chat session.</h5>
                <div className="form-group row mt-3 mx-auto">
                    <div className="col-sm-12">
                        <input type="text" className="form-control" id="userName" placeholder="Name"
                               style={{marginBottom: "15px"}}
                               value={user} onChange={handleChangeUser}/>
                        <input type="text" className="form-control" id="McecsUserName" placeholder="MCECS username"
                               style={{marginBottom: "15px"}}
                               value={mcecsUserName} onChange={handleChangeMcecs}/>
                        <textarea className={"form-control border-danger" + ((wordCount >= 200) ? '' : 'border-danger')}
                                  maxLength={whitespaceLength + 200} id="issueInfo"
                                  placeholder="Brief description of issue (max 200 characters)" style={{marginBottom: "10px"}}
                                  value={issueInfo} onChange={handleChangeIssueInfo}/>
                        <div className={"text-muted small"}
                             style={{right: 20, position: 'absolute', marginBottom: '10px'}}>
                            {wordCount}/200
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 mx-auto m-3">
                        <button type="submit" className="btn btn-success btn-block" id="startChatBtn"
                                onClick={handleSubmit}>
                            Join Support Channel
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default WelcomePage;
