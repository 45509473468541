import { React, useEffect } from 'react';
import { useState } from 'react';

function Users(props) {

    const[users, setUsers] = useState([]);

    //Gets current day of the week

    Date.prototype.getWeekDay = function() {
        let weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        return weekday[this.getDay()];
    }

    //Updates list of users when users join/leave

    useEffect(() => {
        props.socket.on('add user', function(userInfo) {
            setUsers(userInfo);

            //console.log("adding list of users client side", users)

        })

        props.socket.on('remove user', function(userInfo) {
            setUsers(userInfo);
        })

        //console.log(users);

    }, [])


    //Calculates whether the support chat is "online" (or not) based on CAT hours

    const calculateTime = () => {
        
        let currTime = new Date(); 
        let offset = currTime.getTimezoneOffset() / 60; 

        let day = currTime.getUTCDay();

        let hour = currTime.getUTCHours() - offset;
        if(currTime.getDate() != currTime.getUTCDate()) {
            hour += 24;
        }

        if(day === 7)
        {
            return 'Offline';
        }
        else if(day === 6)
        {
            let open = 12;
            let close = 18;

            if(hour >= open && hour < close ) {
                return 'Online'
            }
            else
            {
                return 'Offline'
            }
        } else {
            console.log("else", hour)

            let open = 8;
            let close = 18;

            if(hour >= open && hour < close ) {
                return 'Online'
            }
            else
            {
                return 'Offline'
            }
        }
    }

    return (
        <div style={{display: 'inline-block', width: '400px',
            wordWrap: 'break-word'}}>
            <ul>
                <div>
                    <li className="list-group-item justify-content-center" style={{textAlign: 'center'}}>
                        <b>Cat Support Status</b>
                    </li>
                    <li className="list-group-item justify-content-center" style={{textAlign: 'center'}}>
                        {calculateTime() === 'Online' ?
                            ( <span style={{color: 'green'}}>●   </span>
                            ):
                            (<span style={{color: 'red'}}>●   </span>
                            )}
                        {calculateTime()}
                    </li>
                </div>
            </ul>
            <br/><br/>
            <ul>
                <div>
                    <li className="list-group-item justify-content-center" style={{textAlign: 'center'}}>
                        <b>List of Connected Users</b>
                    </li>
                    {/*-------------Map over list of users---------------*/}
                    {
                        users.map(user => {
                            return(
                                <li className="list-group-item justify-content-center" style={{textAlign: 'center'}}>
                                    {user.name}
                                </li>
                            )
                        })
                    }
                </div>
            </ul>
        </div>

    )
}

export default Users;
