const config = {
    rocket: {
        scheme: "https",
        host: "rocket.cat.pdx.edu",
    },
    support: {
        scheme: "https",
        host: "support.cat.pdx.edu",
    },
};

module.exports = config;
