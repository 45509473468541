import { React } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

function IdleAlert(props) {

    return (
        <Popup open={true} position={"right center"} closeOnEscape={false} closeOnDocumentClick={false}>
            <div id={'popup-alert'} style={{padding: '50px', textAlign: 'center'}} >
                Your session has expired! You can rejoin the session by clicking the "Rejoin Session" button at the bottom of the page. Please consider filling out our survey. Thank you!
                <br/>
                <button style={{margin: '15px'}} onClick={() => {props.closeIdleAlert()}} className="btn btn-danger">
                    Close
                </button>
                <a
                    className="btn btn-success"
                    style={{margin: '15px'}}
                    href="https://portlandstate.qualtrics.com/jfe/form/SV_cANUko2JJI0Jalg"
                    target="_blank"
                >
                    Take the survey
                </a>
            </div>
        </Popup>
    )
}

export default IdleAlert;