import React from 'react';
import { useState, useEffect, useLayoutEffect, useRef } from 'react';

function ScalableImage(props)
{
    const [enlarged, setEnlarged] = useState(false);
    const size = enlarged ? "100%" : "200px";
    const src = props.src;

    const imageOnClick = (e) =>
    {
        e.preventDefault();
        setEnlarged(!enlarged);
    };

    return (
        <img src={src} className="float-left img-fluid btn" style={{width: size}} onClick={imageOnClick} alt="image" />
    );
}

export default ScalableImage;
