import React from 'react';
import { useState, useEffect } from 'react';
import Linkify from "react-linkify";
import Users from '../Users/Users';
import FileUpload from '../FileUpload/FileUpload';
import ScalableImage from './ScalableImage';
import {useIdleTimer} from "react-idle-timer";
import io from 'socket.io-client';
import IdleAlert from "../IdleAlert/IdleAlert";
import "./Chat.css"
import config from "../../config";
const { v4: uuidv4 } = require('uuid');

function Chat (props)
{
    //State Variables
    const [socket, setSocketConn] = useState(null);
    const [clientId, setClientId] = useState(null);
    const [listOfMessages, setListOfMessages] = useState([]);
    const [message, setMessage] = useState("");

    const [userIdle, setUserIdle] = useState(false);
    const [killSession, setKillSession] = useState(false);
    const [showIdleAlert, setShowIdleAlert] = useState(false);

    const [userJoin, setUserJoin] = useState(false);
    const [firstMessage, setFirstMessage] = useState(true);

    const notificationEnabled = 'Notification' in window;

    // Uri endpoints
    const avatarUri = `${config.rocket.scheme}://${config.rocket.host}/avatar/`;

    //Receives chat messages from the server
    useEffect(async () => {

        // Generate UUID
        let clientId = localStorage.getItem('support-uuid');
        if(!clientId){
            clientId = uuidv4();
            localStorage.setItem('support-uuid', clientId);
        }
        await setClientId(clientId);

        const socketLocal = await io(`${config.support.scheme}://${config.support.host}/`);
        await setSocketConn(socketLocal);

        //Send user join message
        if(!userJoin)
        {
            //console.log("user join");
            await socketLocal.emit('user join', {user: props.user, mcecsUserName: props.mcecsUserName, issueInfo: props.issueInfo, clientId: clientId});
            await socketLocal.emit('send alert', {user: props.user, mcecsUserName: props.mcecsUserName, issueInfo: props.issueInfo, clientId: clientId});
            setUserJoin(true);
        }

        //Receive chat message from server
        if (!userIdle || killSession) { //} || !killSession) {
            socketLocal.on('chat message', function (msg) {
                const messageObj = JSON.parse(msg);

                const messageText = messageObj.text
                const attachments = messageObj.attachments ? messageObj.attachments : [];
                const newMessages = [];

                if (messageText.length > 0) {

                    // emojify
                    //var emojifiedText = joypixels.shortnameToImage(messageText);

                    // add message to list
                    newMessages.push({
                        user: messageObj.user,
                        //message: emojifiedText,
                        message: messageText,
                        type: 'text',
                    });
                }

                attachments.forEach((attachment) => {
                    if (attachment.image_url) {
                        newMessages.push({
                            user: messageObj.user,
                            message: '',
                            image: attachment.blob,
                            type: 'image',
                        });
                    }
                });

                setListOfMessages((prevMessages) => [...prevMessages, ...newMessages]);

                // send notification
                if(messageObj.user != props.user && document.hidden && notificationEnabled) {
                    var notify = new Notification("New CAT Support Chat Message", {
                        body: messageText
                    });
                }
            });
        }

        socketLocal.on('user block', function () {
            socketLocal.disconnect();
            alert("Your connection was deactivated. Please refresh the page if you need to connect to support.")
        });

        socketLocal.on("reconnect", () => {
            socketLocal.emit("user join", {user: props.user, mcecsUserName: props.mcecsUserName, issueInfo: props.issueInfo, clientId: clientId});
        });

        //Disconnect on unmount

        return () => {
            socketLocal.emit('remove user', "Chat unmount", clientId);
            socketLocal.disconnect();
        }
    }, []);

    //Sends chat message

    const handleSubmit = (event) =>
    {
        event.preventDefault();

        if(message === "")
        {
            window.alert("Please enter a message");
            return false;

        }
        else if(message.length > 2000)
        {
            window.alert("Sorry, messages must be less than 2000 characters. Your message is "+message.length+" characters long.");
            return false;
        }
        else {
            setMessage(message);

            const messageObj = {user: props.user, text: message}
            const userData = {user: props.user, mcecsUserName: props.mcecsUserName}

            if(firstMessage)
            {
                //console.log("emitting first message");
                socket.emit('first message', userData);
                setFirstMessage(false);
            }

            socket.emit('chat message', JSON.stringify(messageObj));
            setMessage('');
            //setListOfMessages((prevMessages) => [...prevMessages, {user: messageObj.user, message: messageObj.text}]) //for testing purposes

            window.scroll({
                top: document.body.scrollHeight,
                left: 0,
                behavior: 'smooth',
            });
        }
    }

    const handleChatInput = (event) =>
    {
        event.preventDefault();
        setMessage((event.target.value));
        setMessage(props.cleanAndConvertMessage(event.target.value));
    }

    const closeIdleAlert = () => {
        setShowIdleAlert(false);
    }

    const renderReconnectButton = () => {
        let retVal;

        if(userIdle && killSession)
        {
            retVal = (<button
                className="btn btn-success btn-block"
                style={{
                    position: 'fixed',
                    right: 10,
                    bottom: 10,
                    width: '200px'
                }}
                onClick={() => {setKillSession(false); setUserIdle(false); handleReconnect(); socket.emit('user join', {user: props.user, mcecsUserName: props.mcecsUserName, issueInfo: props.issueInfo, clientId: clientId}); socket.connect();}}
            >
                Rejoin Session
            </button>)
        }
        else if(userIdle && !killSession) {
            retVal = (<button
                className="btn btn-success btn-block"
                style={{
                    position: 'fixed',
                    right: 10,
                    bottom: 10,
                    width: '200px'
                }}
                onClick={handleReconnect}
            >
                Rejoin Session
            </button>)
        }
        else if(!userIdle && killSession) {
            retVal = (<button
                className="btn btn-success btn-block"
                style={{
                    position: 'fixed',
                    right: 10,
                    bottom: 10,
                    width: '200px'
                }}
                onClick={() => {setKillSession(false);}}
            >
                Rejoin Session
            </button>)
        }
        else {
            retVal = (<a
                    className="btn btn-danger btn-block"
                    style={{
                        position: 'fixed',
                        right: 10,
                        bottom: 10,
                        width: '200px'
                    }}
                    onClick={() => {setKillSession(true)}}
                    href="https://portlandstate.qualtrics.com/jfe/form/SV_cANUko2JJI0Jalg"
                    target="_blank"
                >
                    End Session
            </a>)
        }

        return retVal;
    }

    const componentDecorator = (href, text, key) => (
        <a href={href} key={key} target="_blank">
            {text}
        </a>
    );

    //Session timeout logic - start

    const reconnectUser = (reconnect) => {
        setUserIdle(!reconnect);
        setShowIdleAlert(false);
    }

    const handleSessionEnd = (end) => {
        setKillSession(end);
    }

    const handleOnIdle = event => {
        console.log('user is idle', event)
        console.log('last active', getLastActiveTime())
        setUserIdle(true);
        setShowIdleAlert(true);
    }

    const handleOnActive = event => {
        console.log('user is active', event)
    }

    const handleOnAction = (e) => {
        //console.log('user did something', e);
    }

    const { getLastActiveTime, reset } = useIdleTimer({
        timeout: 1000 * 60 * 30, // last number indicates the number of minutes before the session expires
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500,
        stopOnIdle: true
    })

    const handleReconnect = (event) =>
    {
        event.preventDefault();
        reconnectUser(true);
        setUserIdle(false);
    }

    useEffect(() => {
        if(!socket)
            return;

        //If user is idle or session is not active, disconnect. Else if active and not idle, connect (if disconnected)

        if(userIdle || killSession)
        {
            if(socket.connected) {
                if(userIdle)
                    socket.emit('remove user', 'session timeout', props.user, clientId)
                else if(killSession)
                    socket.emit('remove user', 'session end', props.user, clientId)

                socket.disconnect();
            }
        }
        else if (!userIdle && !killSession)
        {
            if(socket.disconnected) {
                socket.emit('user join', {user: props.user, mcecsUserName: props.mcecsUserName, issueInfo: props.issueInfo, clientId: clientId});
                socket.connect();
            }

            reset(); //reset idle timer
        }
    }, [userIdle, killSession])

    //Session timeout logic - end

    const onNewImage = (user, imgSrc) =>
    {
        setListOfMessages((prevMessages) => [...prevMessages, {
            user: user,
            message: '',
            image: imgSrc,
            type: 'image',
        }]);
    };

    if(!socket){
        return <div> Connecting... </div>
    }

    if(notificationEnabled){
        Notification.requestPermission().then(function(result) {
            console.log(result);
        });
    }

    return(
        <div style={{display: 'flex'}}>
            <div style={{display: 'inline-block', width: '800px',
                wordWrap: 'break-word'}}>
                <li className="list-group-item">
                    <div className={"d-block "}>
                        <ul className={"fa-ul"}>
                            <li className={"sm"}>
				<span className="fa-li">
				    <i className="fas fa-clock"/>
				</span>

                                Our hours are <strong>8am-6pm</strong> Monday to Friday, and <strong>12-5pm</strong> on Saturdays.
                            </li>

                            <li className={"sm"}>
				<span className="fa-li">
				    <i className="fas fa-info-circle"/>
				</span>

				Please allow a couple minutes for a response during these hours.  Outside our normal hours you may be able to contact us here, but a response is not guaranteed.
                            </li>

                            <li className={"sm"}>
				<span className="fa-li">
				    <i className="fas fa-users"/>
				</span>

				This is a public channel, please do not share passwords or other sensitive information here.
                            </li>

                            <li className={"sm"}>
				<span className="fa-li">
				    <i className="fas fa-user"/>
				</span>

				Need an MCECS account or forgot your password? You can activate your account or reset your password via&nbsp;
				<a href="https://cat.pdx.edu/cram" target="_blank">
				    CRAM
				</a>
			    </li>

                            <li className={"sm"}>
				<span className="fa-li">
				    <i className="fas fa-building"/>
				</span>

				If you have a question about PSU IT services that is not related to MCECS, please visit OIT&nbsp; 
				<a href="https://www.pdx.edu/technology/support" target="_blank">
				    here.
                                </a>
			    </li>
                        </ul>
                    </div>
                </li>
                <li className="list-group-item d-flex" style={{minHeight: 75}}>
                    <img src={avatarUri + encodeURI('Support Bot')} alt="user"
                         className="rounded float-left" style={{height: 45, marginRight: 15}}/>
                    <div className="d-block">
                        <strong>{'Support Bot'}</strong>
                        <p style={{marginBottom: 0}} >Welcome {props.user}! Please tell us about your issue and someone will be right with you.</p>
                    </div>
                </li>
                {/*--------------------Map over list of messages------------------------*/}
                {listOfMessages.map(list => {
                    return list.type === 'text' ? (
                        <li className="list-group-item d-flex" style={{minHeight: 75}}>
                            <img src={avatarUri + encodeURI(list.user)} alt="user"
                                 className="rounded float-left" style={{height: 45, marginRight: 15}}/>
                            <div className="d-block">
                                <strong>{list.user}</strong>
                                <Linkify componentDecorator={componentDecorator}>
                                    <p style={{marginBottom: 0}}>{list.message}</p>
                                </Linkify>
                            </div>
                        </li>
                    ) : (
                        <li className="list-group-item d-flex" style={{minHeight: 75}}>
                            <img src={avatarUri + encodeURI(list.user)} alt="user"
                                 className="rounded float-left" style={{height: 45, marginRight: 15}}/>
                            <div className="d-block">
                                <strong>{list.user}</strong>
                                <div>
                                    <Linkify componentDecorator={componentDecorator}>
                                        <ScalableImage src={list.image} />
                                    </Linkify>
                                </div>
                            </div>
                        </li>
                    );
                })}
                <br/>
                {/*-----------------Show idle alert if session ends or user becomes idle-------------*/}
                {(userIdle && showIdleAlert) && <IdleAlert reconnectUser={reconnectUser} closeIdleAlert={closeIdleAlert} />}
                <form id="chatForm">
                    {!userIdle && !killSession && (
                        <div className="input-group mb-3">
                            <div className="input-group-append">
                                <button className="btn btn-outline-secondary"
                                        type="button"
                                        aria-label="Upload File"
                                        data-toggle="modal"
                                        data-target="#file-upload-modal"
                                >
                                    <span className="far fa-images" aria-hidden={true}/>
                                </button>
                            </div>
                            <input id="input" type="text" className="form-control" placeholder="Enter a message..."
                                   aria-label="Message Input" aria-describedby="Input" value={message}
                                   onChange={handleChatInput}>
                            </input>
                            <div className="input-group-append">
                                <button id="sendMessage" className="btn btn-outline-secondary" type="submit"
                                        onClick={handleSubmit}>
                                    Send
                                </button>
                            </div>
                        </div>
                    )}
                </form>
            </div>
            <div id='listOfUsers'>
                <Users socket={socket} user={props.user}/>
            </div>
            <FileUpload user={props.user} onNewImage={onNewImage} />
            {renderReconnectButton()}
        </div>
    );
}

export default Chat;


