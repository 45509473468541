import { React, useEffect, useState, useRef } from 'react';
import './FileUpload.css';
import config from '../../config';
import Dropzone from 'dropzone'


Dropzone.autoDiscover = false;
let dz = null;

function FileUpload(props)
{
    const user = props.user;
    const onNewImage = props.onNewImage;

    const [dzRegistered, setDzRegistered] = useState(false);
    const [hasFiles, setHasFiles] = useState(false);

    const dropzoneRef = useRef(null);
    const dropAreaRef = useRef(null);
    const previewTemplateRef = useRef(null);

    useEffect(() => {
        setDzRegistered(true);
        if (dz !== null) return;

        const previewTemplate = previewTemplateRef.current.innerHTML;
        dz = new Dropzone(dropzoneRef.current, {
            url:               `${config.support.scheme}://${config.support.host}/file/upload`,      // POST endpoint
            acceptedFiles:     ".jpg,.jpeg,.png",   // Comma separated list of accepted extensions
            thumbnailWidth:    60,
            thumbnailHeight:   60,
            parallelUploads:   5,
            previewTemplate:   previewTemplate,     // HTML template for each uploaded file
            autoQueue:         false,               // Whether to auto-begin the uploading
            previewsContainer: "#previews",
            clickable:         ".fileinput-button", // Button to add files
            maxFilesize:       5,                   // Mb

            // Callback: right before sending the file
            sending:           function(file, xhr, formData) {
                formData.set("username", user);
            },

            // Callback: file successfully uploaded
            success:           function(file, res) {
                const reader = new FileReader();
                const avatarUri = `${config.rocket.scheme}://${config.rocket.host}/avatar/${encodeURI(user)}`;
                // const avatarUri = `${rocketScheme}://${rocketHost}/avatar/${encodeURI(user)}`;

                reader.onload = function(e) {
                    const imgSrc = e.target.result;
                    onNewImage(user, imgSrc);
                };

                reader.readAsDataURL(file);
            },
        });

        dz.on("addedfile", function(file) {
            file.previewElement.querySelector(".start").onclick = function() { dz.enqueueFile(file); };
            file.previewElement.querySelector(".cancel").onclick = function() { dz.removeFile(file); };
            setHasFiles(true);
        });

        dz.on("totaluploadprogress", function(progress) {
            document.querySelector("#total-progress .progress-bar").style.width = progress + "%";
        });

        dz.on("sending", function(file) {
            document.querySelector("#total-progress").style.opacity = "1";
            file.previewElement.querySelector(".start").setAttribute("disabled", "disabled");
        });

        dz.on("queuecomplete", function(progress) {
            document.querySelector("#total-progress").style.opacity = "0";
        });
    });

    /*-----File Upload Modal-----*/
    return (
        <div className="modal fade" id="file-upload-modal" tabindex={-1} role="dialog" aria-labelledby="modalLabel" aria-hidden={true}>
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalLabel">Upload File</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="close">
                            <span aria-hidden={true}>&times;</span>
                        </button>
                    </div>
                    <div id="dropzone-elem" ref={dropzoneRef} className="modal-body">
                        {/*----------Dropzone-----------*/}
                        <div id="dropzone-actions" className="row my-4 align-items-center">
                            <div className="col-lg-7 col-sm-12 my-1">
                                <span className="btn btn-success fileinput-button dz-clickable">
                                    <i className="icon-plus small-text"></i>
                                    <span>Upload Images</span>
                                </span>
                            </div>
                            <div className="col-lg-5 col-sm-12 my-1">
                                <span className="fileupload-process">
                                    <div id="total-progress"
                                         className="progress progress-striped active"
                                         role="progressbar"
                                         aria-valuemin="0"
                                         aria-valuemax="100"
                                         aria-valuenow="0"
                                    >
                                        <div className="progress-bar progree-bar-success"
                                             style={{width:"0%"}}
                                             data-dz-uploadprogress
                                        ></div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div id="drop-area" ref={dropAreaRef} style={{display: hasFiles ? "block" : "flex"}}>
                            <div className="files" id="previews" ref={previewTemplateRef}>
                                { dzRegistered ? <div></div> : (
                                <div
                                     className="row align-items-center position-relative bg-white d-flex justify-content-between py-2 border border-top-0 border-right-0 border-left-0"
                                >
                                    <div className="col-lg-2 col-sm-2 col-xs my-1">
                                        <span className="preview"><img data-dz-thumbnail /></span>
                                    </div>
                                    <div className="col-lg-2 col-sm-2 col-xs my-1">
                                        <div className="name text-truncate" data-dz-name></div>
                                        <strong className="error text-danger" data-dz-errormessage></strong>
                                    </div>
                                    <div className="col-lg-3 col-sm-0 col-xs-0 d-xs-none d-sm-none d-lg-block d-xl-block">
                                        <p className="size" data-dz-size></p>
                                        <div className="progress progress-striped active"
                                             role="progressbar"
                                             aria-valuemin="0"
                                             aria-valuemax="100"
                                             aria-vlauenow="0">
                                            <div className="progress-bar progree-bar-success" style={{width:"0%"}} data-dz-uploadprogress></div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-sm-8 col-xs my-1 d-flex justify-content-between">
                                        <button className="btn btn-primary start">
                                            <i className="icon-arrow-circle-o-up icon-offset"></i>
                                            <span>Upload</span>
                                        </button>
                                        <button data-dz-remove className="btn btn-warning text-while cancel">
                                            <i className="icon-ban icon-offset"></i>
                                            <span>Cancel</span>
                                        </button>
                                        <button data-dz-remove className="btn btn-danger delete">
                                            <i className="icon-times-circle-o icon-offset"></i>
                                            <span>Delete</span>
                                        </button>
                                    </div>
                                </div> ) }
                            </div>
                            { hasFiles ? <div></div> : <div className="text-center dropzone-init-message">
                                <span>Drop images here to upload.</span>
                            </div> }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Done</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FileUpload;
